import React, { Component } from 'react'
import { Container, Navbar, Nav, NavbarBrand, NavItem, NavLink, Collapse, NavbarToggler, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Flag } from 'semantic-ui-react'
import { FaBars } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import { EXPLORER_URL } from '../../constants/defaultValues'

import '../../assets/css/V3NavMenu.css'

// Helpers
import { withAffCodeURL } from '../../helpers/helpers';

// SVGs
import { ReactComponent as IconMindmap } from '../../assets/img/v3-nav-menu/icon-mindmap.svg';
import { ReactComponent as IconOpportunity } from '../../assets/img/v3-nav-menu/icon-opportunity.svg';
import { ReactComponent as IconFinancialOverview } from '../../assets/img/v3-nav-menu/icon-financial-overview.svg';
import { ReactComponent as IconDao } from '../../assets/img/v3-nav-menu/icon-dao.svg';
import { ReactComponent as IconExplorer } from '../../assets/img/v3-nav-menu/icon-explorer.svg';
import { User } from '../../providers/User'

class V3NavMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    handleLogout = () => {
        localStorage.clear()
        window.location.href = '/'
    }

    clearStorage = () => {
        localStorage.clear()
    }
    
    render() {
        const { t, active, handleClose, handleModal } = this.props;
        return (
            <>
            <div className={`v3-nav-menu-wrap ${active ? 'active' : ''}`}>
                <nav className={`v3-nav-menu`}>
                    <ul className='nav-menu-items'>
                        <li className='navbar-toggle'>
                            <AiOutlineClose onClick={handleClose} />
                        </li>
                        <li>
                            <a href={withAffCodeURL('mindmap')}>
                                <IconMindmap width={55} />
                                <span className='nav-menu-items-text'>Mindmap</span>
                            </a>
                        </li>
                        <li>
                            <a href={withAffCodeURL('https://govato.io')} target='_blank' rel="noopener noreferrer">
                                <IconOpportunity width={60} />
                                <span className='nav-menu-items-text'>Opportunity</span>
                            </a>
                        </li>
                        <li>
                            <a href={withAffCodeURL('financial-overview-and-roadmap-2024')}>
                                <IconFinancialOverview width={45} />
                                <span className='nav-menu-items-text'>Financial Overview</span>
                            </a>
                        </li>
                        <li>
                            <a href={withAffCodeURL('leaderboard')}>
                                <IconDao width={45} />
                                <span className='nav-menu-items-text'>Leaderboard</span>
                            </a>
                        </li>
                        {/* <li>
                            <a href={withAffCodeURL('dao')}>
                                <IconDao width={45} />
                                <span className='nav-menu-items-text'>DAO</span>
                            </a>
                        </li> */}
                        <li>
                            <a href={withAffCodeURL('https://wallet.ovato.com')} target='_blank' rel="noopener noreferrer">
                                <IconExplorer width={55} />
                                <span className='nav-menu-items-text'>Explorer</span>
                            </a>
                        </li>
                        <li>
                        { User.hasToken() ? 
                            <a href="#" onClick={this.handleLogout}>
                                <span className='nav-menu-mobile-btn'>Logout</span>
                            </a>
                        :
                            <a target="_blank" href={withAffCodeURL("https://wallet.ovato.com/")} onClick={this.clearStorage}>
                                <span className='nav-menu-mobile-btn'>Send/Receive <br /> Login</span>
                            </a>
                        }
                        </li>
                    </ul>
                </nav>
                <div className='v3-nav-menu-overlay' onClick={handleClose}></div>
            </div>
            </>
        )
    }
}
export default withTranslation()(V3NavMenu)