import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import '../../assets/css/NFTHeaderSeries.css'

// Images
import ImgStartingSoon from '../../assets/img/nft-header-series/img-starting-soon.png'
import ImgAvailableNow from '../../assets/img/nft-header-series/img-available-now.png'

import ImgSmall from '../../assets/img/nft-header-series/img-small.png'
import ImgMedium from '../../assets/img/nft-header-series/img-medium.png'
import ImgLarge from '../../assets/img/nft-header-series/img-large.png'

import ImgSeriesA from '../../assets/img/nft-header-series/img-series-a.png'
import ImgSeriesAMobile from '../../assets/img/nft-header-series/img-series-a-mobile.png'


class NFTHeaderSeries2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  
  render() {
    const { t } = this.props;

    return (
        <div className='text-center img-wrap v2-tl-mt-2'>
            <img src={ImgSeriesA} width={606} className='desktop mx-auto' />
            <img src={ImgSeriesAMobile} width={300} className='mx-auto mobile' />
        </div>
    );
  }
}
export default withTranslation()(NFTHeaderSeries2);