import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import React, { Component } from 'react';
import yellowPaper from '../assets/img/OVATO_YELLOWPAPER.pdf';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import ImgBanner from '../assets/img/img-faqs-banner.png'

class Faqs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fade: false,
      selected: 1,
      faqs: [],
      searchFaqs: [],
      isSearching: false
    };
  }
  toggle = (i) => {
    if (this.state.selected == i) {
        return this.setState({selected:null});
    }
    this.setState({selected:i});
  }
  setQ = (val) => {
    if (val.length > 2) {
        const result = this.state.faqs.filter(record => {
            return record.keywords.toLowerCase().indexOf(val.toLowerCase()) > -1;
        }).map(function(item) {
            return item.id
        });
        this.setState({
            searchFaqs: result,
            isSearching: true
        });
    } else {
        this.setState({
            searchFaqs: [],
            isSearching: false
        });
    }
  }
  faqsSearchClass = (val) => {
    return (this.state.selected == val ? 'active' : '') + ' ' + (this.state.searchFaqs.includes(val) ? 'faqs-found' : '') + ' ' + (this.state.isSearching ? 'faqs-searching' : '')
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  componentDidMount() {
    // Trigger update
    this.setState({ faqs: [
      /* 1 */
        {id: 1, keywords: "I can't seem to open my wallet, what do I do? Try resetting your password if you can't log into your account. If you're still unable to do so you can create a trouble ticket or email support@Ovato.com"},
        /* 2 */
        {id: 2, keywords: "I keep getting an error message when I'm trying to send coins An error message can happen for a number of reasons. You may have an invalid address so please double check. Additionally please ensure that the coins you're trying to send or not locked through stacking which exist through your wallet If the problem persist you can create a trouble ticket or email support@Ovato.com"},
        /* 3 */
        {id:3, keywords: "what is the difference between stacking for value and stacking for coins? Stacking for value Stacking for value exists when you're trying to just get a return on an equivalent to some denominator of FIat. For instance you may elect to get 30% of the value of your holdings in USDT. For example you have $100 and you wish to engage in a stacking program that pays you $130 worth of OVO. This will be paid regardless of what the market conditions are for OVO at the conclusion of the term Stacking for coins This works oppositely to stacking for value in that you were paid based on the number of coins For example if you commit 100 OVO to the same term of time and earn 30% you will be earning 30 OVO Regardless of what the price of the coin does"},
        /* 4 */
        {id:4, keywords: "can I speak to somebody? Absolutely simply place that in your trouble ticket or email support@Ovato.com."},
        /* 5 */
        {id:5, keywords: "what exchanges are you currently traded on? We are currently trading on the following exchanges:"},
        /* 6 */
        {id:6, keywords: "How can I buy more? The best place to buy is through exchanges. However, in special situations, if you would like to be placed on the list, please email support@Ovato.com."},
        /* 7 */
        {id:7, keywords: "What stores accept Ovato in my neighbourhood? The list is growing daily please download the app to GEO target a store near you"},
        /* 8 */
        {id:8, keywords: "Can I strip my Ovato out of my NFT? although we don't recommend it because it will enhance the value of the NFT. Yes you certainly can for a quick and easy sale if needed"},
        /* 9 */
        {id:9, keywords: "What is the difference between stacking on the web or app and doing a cold storage stack? A cold storage stack is where you're placing it with us for safekeeping in Long term cold storage"},
        /* 10 */
        {id:10, keywords: "Can I use Web wallet with the same account as my app Wallet Yes"},
        /* 11 */
        {id:11, keywords: "Can I get individual help? yes email support@ovato.com"},
        /* 12 */
        {id:12, keywords: "What are the current secondary markets or exchanges? We are adding new exchanges right now you can find a https://ovato.com/en/exchange"},
        /* 13 */
        {id:13, keywords: "Where can I see my transactions that are done over the block chain? You can check your transactions and any transactions on ovato https://explorer.ovato.com"},
        /* 14 */
        {id:14, keywords: "Are my transactions private? Yes While the transactions themselves are published over the Blockchain. The identities are not published"},
        /* 15 */
        {id:15, keywords: "What is Ovato's main source of jurisdiction? Ovato Blockchain has no jurisdiction per se however corporate foundational activities jurisdiction is Singapore"},
        /* 16 */
        {id:16, keywords: "Is Ovato a safe store of value? Yes , Ovato is very safe"},
        /* 17 */
        {id:17, keywords: "Am I protected if my credentials are lost or stolen? No you are not. Please keep your credentials safe at all times. You are the custodial of your own money. While we do provide some services related to lost credentials We cannot protect you if someone else has access to it"},
        /* 18 */
        {id:18, keywords: "What is the maximum supply? The maximum supply that can ever be in existence is 34.4 million coins"},
        /* 19 */
        {id:19, keywords: "Is there a concentration level that is easily available to view? Yes, here is our top 100 list Click here"},
        /* 20 */
        {id:20, keywords: "Are there mining opportunities? Yes, please discover our mining page  Click Here >"},
        /* 21 */
        {id:21, keywords: "I get an error message when I try and sell coins Your wallet is either stacked or locked for some reason please contact support@ovato.com"},
        /* 22 */
        {id:22, keywords: "I have discovered a bug- what should I do? Great! we pay for Bugs. Discover now Click Here >"},
    ] });
  }
  render() {
    const { t } = this.props;
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    return (
        <>
      <div className='blue-nav support_main faqs-main'>
        {/* HEADER */}
        <TopNav />
         {/* BANNER */}
        <div className='new-first-row brand-main-new banner-faqs-new'>
          <Container>
            <Row className='align-center'>
              <Col md={6}>
                <h1 className='Poppins font-55 bold dark-text-5 text-uppercase faqs-banner-heading'>{t('faqs.faqs')}</h1>
                <p className='poppins font-19 regular dark-text-5'>{t('faqs.how')}</p>
                <div className='faqs-search-wrap'>
                    <input type='text' placeholder={t('faqs.search')} className='faqs-search' onChange={(e) => this.setQ(e.target.value)} />
                </div>
              </Col>
              <Col md={6} className='yellow-paper-banner-new-col-2'>
                <div className='img-wrap desktop banner-faqs-img-wrap'>
                  <img src={ImgBanner} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* SECTION 2 */}
        <section className='white-top-curve-8 faqs-2'>
          <Container className='white-section-2'>
            <div className='faqs-wrap'>
                {/* 1 */}
                <div className={"faqs-col " + this.faqsSearchClass(1)}>
                    <div className='faqs-title' onClick={() => this.toggle(1)}>
                        1) {t('faqs.question1')}
                    </div>
                    <div className='faqs-content'>
                         <p>{t('faqs.answer1')}</p>
                    </div>
                </div>
                {/* 2 */}
                <div className={"faqs-col " + this.faqsSearchClass(2)}>
                    <div className='faqs-title' onClick={() => this.toggle(2)}>
                        2) {t('faqs.question2')}
                    </div>
                    <div className='faqs-content'>
                         <p>{t('faqs.answer2')}</p>
                        <p>{t('faqs.answer22')}</p>
                    </div>
                </div>
                {/* 3 */}
                <div className={"faqs-col " + this.faqsSearchClass(3)}>
                    <div className='faqs-title' onClick={() => this.toggle(3)}>
                        3) {t('faqs.question3')}
                    </div>
                    <div className='faqs-content'>
                         <p>{t('faqs.answer3')}</p> 
                        <p>{t('faqs.answer33')}</p>
                        <p>{t('faqs.answer333')}</p>
                        <p>{t('faqs.answer3333')}</p>
                        <p>{t('faqs.answer33333')}</p>
                    </div>
                </div>
                {/* 4 */}
                <div className={"faqs-col " + this.faqsSearchClass(4)}>
                    <div className='faqs-title' onClick={() => this.toggle(4)}>
                        {'4)'} {t('faqs.question4')}
                    </div>
                    <div className='faqs-content'>
                         <p>{t('faqs.answer44')}</p> 
                    </div>
                </div>
                {/* 5 */}
                <div className={"faqs-col " + this.faqsSearchClass(5)}>
                    <div className='faqs-title' onClick={() => this.toggle(5)}>
                       {'5)'} {t('faqs.question5')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer5')}</p> 
                         <ul className='faqs-ul'>
                            <li><a href='http://probit.com/' target="_blank">Probit</a></li>
                            <li><a href='https://www.lbank.info/' target="_blank">Lbank</a></li>
                            <li><a href='http://whitebit.com/' target="_blank">Whitebit</a></li>
                            <li><a href='http://p2pb2b.io/' target="_blank">P2p B2B</a></li>
                         </ul>
                    </div>
                </div>
                {/* 6 */}
                <div className={"faqs-col " + this.faqsSearchClass(6)}>
                    <div className='faqs-title' onClick={() => this.toggle(6)}>
                       {'6)'} {t('faqs.question6')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer66')}</p> 
                    </div>
                </div>
                {/* 7 */}
                <div className={"faqs-col " + this.faqsSearchClass(7)}>
                    <div className='faqs-title' onClick={() => this.toggle(7)}>
                      {'7)'} {t('faqs.question7')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer77')}</p> 
                    </div>
                </div>
                {/* 8 */}
                <div className={"faqs-col " + this.faqsSearchClass(8)}>
                    <div className='faqs-title' onClick={() => this.toggle(8)}>
                      {'8)'} {t('faqs.question8')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer88')}</p> 
                    </div>
                </div>
                {/* 9 */}
                <div className={"faqs-col " + this.faqsSearchClass(9)}>
                    <div className='faqs-title' onClick={() => this.toggle(9)}>
                      {'9)'} {t('faqs.question9')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer99')}</p>

                        <p>{t('faqs.answer99_2')}</p> 
                    </div>
                </div>
                {/* 10 */}
                <div className={"faqs-col " + this.faqsSearchClass(10)}>
                    <div className='faqs-title' onClick={() => this.toggle(10)}>
                      {'10)'} {t('faqs.question10')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer10')}</p> 
                    </div>
                </div>
                {/* 11 */}
                <div className={"faqs-col " + this.faqsSearchClass(11)}>
                    <div className='faqs-title' onClick={() => this.toggle(11)}>
                      {'11)'} {t('faqs.question11')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer11')}</p> 
                    </div>
                </div>
                {/* 12 */}
                <div className={"faqs-col " + this.faqsSearchClass(12)}>
                    <div className='faqs-title' onClick={() => this.toggle(12)}>
                      {'12)'} {t('faqs.question12')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer12')} <a href='https://ovato.com/en/exchange' target="_blank">https://ovato.com/en/exchange</a></p> 
                    </div>
                </div>
                {/* 13 */}
                <div className={"faqs-col " + this.faqsSearchClass(13)}>
                    <div className='faqs-title' onClick={() => this.toggle(13)}>
                      {'13)'} {t('faqs.question13')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer13')} <a href='https://wallet.ovato.com' target="_blank">https://wallet.ovato.com</a></p> 
                    </div>
                </div>
                {/* 14 */}
                <div className={"faqs-col " + this.faqsSearchClass(14)}>
                    <div className='faqs-title' onClick={() => this.toggle(14)}>
                      {'14)'} {t('faqs.question14')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer14')}</p> 
                    </div>
                </div>
                {/* 15 */}
                <div className={"faqs-col " + this.faqsSearchClass(15)}>
                    <div className='faqs-title' onClick={() => this.toggle(15)}>
                        {'15)'} {t('faqs.question15')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer15')}</p> 
                    </div>
                </div>
                {/* 16 */}
                <div className={"faqs-col " + this.faqsSearchClass(16)}>
                    <div className='faqs-title' onClick={() => this.toggle(16)}>
                        {'16)'} {t('faqs.question16')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer16_1')}</p> 
                    </div>
                </div>
                {/* 17 */}
                <div className={"faqs-col " + this.faqsSearchClass(17)}>
                    <div className='faqs-title' onClick={() => this.toggle(17)}>
                        {'17)'} {t('faqs.question17')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer17')}</p> 
                    </div>
                </div>
                {/* 18 */}
                <div className={"faqs-col " + this.faqsSearchClass(18)}>
                    <div className='faqs-title' onClick={() => this.toggle(18)}>
                       {'18)'} {t('faqs.question18')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer18')}</p> 
                    </div>
                </div>
                {/* 19 */}
                <div className={"faqs-col " + this.faqsSearchClass(19)}>
                    <div className='faqs-title' onClick={() => this.toggle(19)}>
                       {'19)'} {t('faqs.question19')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer19')} <a href='/top-100-holders' target="_blank">Click Here {'>'}</a></p> 
                    </div>
                </div>
                {/* 20 */}
                <div className={"faqs-col " + this.faqsSearchClass(20)}>
                    <div className='faqs-title' onClick={() => this.toggle(20)}>
                       {'20)'} {t('faqs.question20')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer20')}  <a href='/mining' target="_blank">Click Here {'>'}</a></p> 
                    </div>
                </div>
                {/* 21 */}
                <div className={"faqs-col " + this.faqsSearchClass(21)}>
                    <div className='faqs-title' onClick={() => this.toggle(21)}>
                       {'21)'} {t('faqs.question21')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer21')}</p> 
                    </div>
                </div>
                {/* 22 */}
                <div className={"faqs-col " + this.faqsSearchClass(22)}>
                    <div className='faqs-title' onClick={() => this.toggle(22)}>
                       {'22)'} {t('faqs.question22')}
                    </div>
                    <div className='faqs-content'>  
                         <p>{t('faqs.answer_22')} <a href="/bug-bounty" target="_blank">Click Here {'>'}</a> </p> 
                    </div>
                </div>
            </div>
          </Container>  
        </section>
        
        {/* FOOTER */}
        <BottomNav />
      </div>
      </>
    );
  }
}
export default withTranslation()(Faqs);