import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// Helpers
import { withAffCodeURL } from '../../helpers/helpers';

import Img1 from '../../assets/img/new-home-4/img-better-for-users.png'

import V3Button from '../buttons/V3Button';

class Section2 extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <section className='v2-mt--4 v2-mt-0 v2-pb-4 v3-home-2 v2-m-pt-13'>
          <Container className='relative z-index-2'>
            <Row className='align-center'>
              <Col sm={6} className='v2-m-order-2 v2-m-mt-3'>
                <div className='img-wrap'>
                  <img src={Img1} className='v2-m-w-300' />
                </div>
              </Col>
              <Col sm={6} className='v2-pl-8 v2-tp-pl-2 v2-m-order-1'>
                  <div>
                      <h2 className='poppins v2-font-36 v2-tp-font-32 v2-m-font-28 bold dark-text-5'>Better for users</h2>
                      <p className='poppins v2-font-16 light dark-text-5'>
                        <ul className='v2-font-16 checked-ul'>
                            <li>Ovato is appreciable</li>
                            <li>Earn on transactions </li>
                            <li>Be your own bank (secure self custody) </li>
                        </ul>
                      </p>
                      <div className='v2-mt-2-5'>
                        <V3Button text='Start Now' href={withAffCodeURL("wallet")} />
                      </div>
                  </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
export default withTranslation()(Section2);