import React, { Component } from 'react';
import TopNav from '../containers/NewTopNav3';
import BottomNav from '../containers/NewBottomNav3';

import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// Components
import LeaderboardTable from '../components/leaderboard/LeaderboardTable';
import LeaderboardTableMobile from '../components/leaderboard/LeaderboardTableMobile';
import LeaderboardFilter from '../components/leaderboard/LeaderboardFilter';
import LeaderboardPagination from '../components/leaderboard/LeaderboardPagination';
import LeaderboardLoadMore from '../components/leaderboard/LeaderboardLoadMore';

// CSS
import '../assets/css/Leaderboard.css'

// Images
import ImgUSA from '../assets/img/leaderboard/img-usa.jpg'


import { Service } from '../providers/Services';
import { Service2 } from '../providers/Service2';

import LoadingBG from '../components/loader/LoadingBG';
import { customConsole } from '../constants/defaultValues';
import LeaderboardTop5 from '../components/leaderboard/LeaderboardTop5';

const transformApiResponse = async(apiResponse) =>{
    let exchData = await Service.getExch()
    // console.log('eeeE::: rate::: ', exchData, apiResponse)
    return Object.entries(apiResponse).map(([id, data]) => ({
      ...data,
      username: data.name,
      no_of_wallets_opened: data.wallets_opened === "NA" ? 0 : parseInt(data.wallets_opened),
      wallet_earnings: (data.wallet_earnings * +exchData.usd).toFixed(2),
      nft_earnings: (data.nft_earnings * +exchData.usd).toFixed(2),
      total_earnings: (data.total_earnings * +exchData.usd).toFixed(2),
      total_number_in_organization: data.total_organisation === "NA" ? 0 : parseInt(data.total_organisation)
    }));
  }

  const transformApiResponseTop5 = async (apiResponse) => {
    let exchData = await Service.getExch();
    
    // Map over each entry and use Promise.all to handle async calls
    const transformedData = await Promise.all(
      Object.entries(apiResponse).map(async ([id, data]) => {
        // const nftDetail = await Service.getNFT(data.wallet.nft);
  
        return {
          ...data,
          // image: nftDetail.nft_name, // Assuming nft_name is the correct field
          // ovo: nftDetail.ovo_tokens,  // Assuming ovo_tokens is the correct field
          username: data.name,
          no_of_wallets_opened: data.wallets_opened === "NA" ? 0 : parseInt(data.wallets_opened),
          wallet_earnings: (data.wallet_earnings * +exchData.usd).toFixed(2),
          nft_earnings: (data.nft_earnings * +exchData.usd).toFixed(2),
          total_earnings: (data.total_earnings * +exchData.usd).toFixed(2),
          total_number_in_organization: data.total_organisation === "NA" ? 0 : parseInt(data.total_organisation),
          nft: id
        };
      })
    );
  
    // Return only the first 5 records
    return transformedData.slice(0, 5);
  };

class Leaderboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            loading: true,
            dataTop5: [],
            loadingTop5: true
        };
    }

    fetchLeaderboardData = async(period) => {
      try{
        this.setState({ loading: true, data:[] });
        const { leaderboard } = await Service.getLeaderboard(period);
        const transformedData = await transformApiResponse(leaderboard);
        customConsole.log('transfored l date::: ', transformedData);
        this.setState({ data: transformedData, loading: false });
          // Service.getLeaderboard(period)
          //   .then(async(res) => {
          //     const { leaderboard } = res;
          //     const transformedData = await transformApiResponse(leaderboard);
          //     customConsole.log('transfored l date::: ', transformedData)
          //     this.setState({ data: transformedData, loading: false });
          //   })
          //   .catch(err => {
          //     customConsole.log(err);
          //     this.setState({ data: [], loading: false });
          //   });
        }
        catch (err) {
          customConsole.log(err);
          this.setState({ data: [], loading: false });
        }
      }

      fetchLeaderboardTop5Data = async() => {
        try{
            this.setState({ loadingTop5: true, dataTop5:[] });
            const { leaderboard } = await Service.getLeaderboard(-1);
            const transformedData = await transformApiResponseTop5(leaderboard);
            this.setState({ dataTop5: transformedData, loadingTop5: false });
          }
          catch (err) {
            customConsole.log(err);
            this.setState({ data: [], loading: false });
          }
        }
      
      handleLoading = (status) => {
        customConsole.log('hello handle loading', status)
        this.setState({loading: status})
      }
    componentDidMount() {
        this.fetchLeaderboardData(1);
        this.fetchLeaderboardTop5Data();
    }
    leaderBoardFilter = (period)=>{
        switch (period) {
            case 'day':
              this.fetchLeaderboardData(1);
              break;
            case 'week':
              this.fetchLeaderboardData(7);
              break;
            case 'month':
              this.fetchLeaderboardData(30);
              break;
            case 'total':
            default:
              this.fetchLeaderboardData(-1);
              break;
          }
    }
  render() {

    const { t } = this.props;

    // const data = [
    //     { username: "JohnDoe", no_of_wallets_opened: 5, wallet_earnings: 1500000, total_number_in_organization: 20 },
    //     { username: "JaneSmith", no_of_wallets_opened: 3, wallet_earnings: 1200000, total_number_in_organization: 15 },
    //     { username: "BobJohnson", no_of_wallets_opened: 7, wallet_earnings: 2000000, total_number_in_organization: 25 },
    //     { username: "EmilyDavis", no_of_wallets_opened: 4, wallet_earnings: 1800000, total_number_in_organization: 18 },
    //     { username: "MarkWilson", no_of_wallets_opened: 6, wallet_earnings: 2200000, total_number_in_organization: 22 },
    //     { username: "SarahBrown", no_of_wallets_opened: 2, wallet_earnings: 1000000, total_number_in_organization: 12 },
    //     { username: "MichaelLee", no_of_wallets_opened: 8, wallet_earnings: 2500000, total_number_in_organization: 30 },
    //     { username: "LisaGarcia", no_of_wallets_opened: 3, wallet_earnings: 1300000, total_number_in_organization: 17 },
    //     { username: "DavidMartinez", no_of_wallets_opened: 5, wallet_earnings: 1900000, total_number_in_organization: 21 },
    //     { username: "KarenClark", no_of_wallets_opened: 4, wallet_earnings: 1600000, total_number_in_organization: 19 }
    // ];

    const { data } = this.state
    
    return (
      <div className='leaderboard-main'>
        <LoadingBG show={this.state.loading} />

        {/* HEADER */}
        <TopNav />

        {/* BANNER */}
        <section className='leaderboard-1'>
            <Container>
                <Row>
                    <Col>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* SECTION 2 */}
        <section className='v2-m-pt-1 leaderboard-2'>
            <Container>

                <Row>
                  <Col>
                    <p className='poppins text-capitalize v2-font-35 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold dark-text-5 lh-1-2 text-center'>The Top 5</p>

                    <LeaderboardTop5 data={this.state.dataTop5} loading={this.state.loadingTop5} />
                  </Col>
                </Row>

                <Row className='align-items-center justify-content-space-between v2-mt-2'>
                    <Col>
                        <h1 className='poppins v2-font-35 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold dark-text-5'>Leaderboard</h1>
                    </Col>

                    <Col className='v2-m-mt-1'>
                        <LeaderboardFilter 
                          leaderboardFilterFunc={this.leaderBoardFilter}
                        />
                    </Col>
                </Row>

                <Row className='v2-mt-2'>
                    <Col>
                        <div className='desktop'>
                            <LeaderboardTable data={data} />
                        </div>

                        <div className='mobile'>
                            <LeaderboardTableMobile data={data} />
                        </div>

                        {/* <div className='desktop v2-mt-2'>
                            <LeaderboardPagination />
                        </div> */}

                        {/* <div className='mobile v2-mt-2'>
                            <LeaderboardLoadMore />
                        </div> */}
                    </Col>
                </Row>
            </Container>
        </section>

        {/* FOOTER */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(Leaderboard);