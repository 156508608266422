import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// Helpers
import { withAffCodeURL } from '../../helpers/helpers';

import V3Button from '../buttons/V3Button';

// Images
import imgHome3 from '../../assets/img/img-new-home-3.png'
import imgJavier from '../../assets/img/new-home-3/img-javier-d.png'
import imgJeffrey from '../../assets/img/new-home-3/img-jeffery-r.png'
import imgAnton from '../../assets/img/new-home-3/img-jessica-antoinette.png'
import imgStar from '../../assets/img/new-home-3/img-star.png'

class section3 extends Component {
  render() {
    const { t } = this.props;
    return (
      <section className='v2-m-pb-4 new-home-3 white-top-curve-2 v3-home-3'>
        <Container className='relative z-index-2'>
          <Row className='align-center'>
            <Col md={6} className='new-home-3-col-right'>
                <div className='new-home-3-col-inner'>
                    <h2 className='poppins v2-font-36 v2-tp-font-32 v2-m-font-28 bold dark-text-5'>Better for merchants</h2>
                    <ul className='v2-font-16 checked-ul'>
                        <li>Save on merchant processing  by as much as 85%</li>
                        <li>Earn extra revenue on boarding</li>
                        <li>Greater security</li>
                    </ul>
                    <div className='v2-mt-2-5'>
                        <V3Button text={'Get Started'} href={withAffCodeURL("wallet")} />
                    </div>
                </div>
            </Col>
            <Col md={6}>
              <div className='img-wrap new-home-3-img-wrap'>
                <img src={imgHome3} />
              </div>
            </Col>
          </Row>

          <Row className='v2-mt-10 v2-tp-mt-6 v2-m-mt-4 align-center'>
            <Col md={12}>
                <h2 className='poppins v2-font-36 v2-tp-font-32 v2-m-font-28 bold dark-text-5 text-center'>Community Reviews</h2>
            </Col>
          </Row>

          <Row className='v2-mt-7'>
            <Col md={4} sm={6} className='v2-tp-w-6 v2-m-w-12'>
                <div className='text-center v3-review-wrap'>
                    <div className='v3-review-img'>
                        <img src={imgJeffrey} />
                    </div>
                    <p className='v3-review-text poppins v2-font-14 light dark-text-3 v2-mt-1-5'>Ovato provides the perfect solution to our banking challenges. On-Boarding was a snap and highly recommend</p>
                    <p className='v3-review-name poppins v2-font-14 bold dark-text-5 v2-mb-0'>Jeffery R.</p>
                    <p className='v3-review-location poppins v2-font-14 medium dark-text-5 v2-mb-0'>Wayne's World</p>
                    <div className='img-wrap v3-review-img-wrap v2-mt-0-5'>
                        <img src={imgStar} width={130} />
                    </div>
                </div>
            </Col>

            <Col md={4} sm={6} className='v2-m-mt-6  v2-tp-w-6 v2-m-w-12'>
                <div className='text-center v3-review-wrap'>
                    <div className='v3-review-img'>
                        <img src={imgJavier} />
                    </div>
                    <p className='v3-review-text poppins v2-font-14 light dark-text-3 v2-mt-1-5'>As a new a foreign student and new immigrant banking can be very challenging. Ovato provided a perfect solution but now that it has already doubled in price. Im part of the movement officially.</p>
                    <p className='v3-review-name poppins v2-font-14 bold dark-text-5 v2-mb-0'>Javier D.</p>
                    <div className='img-wrap v3-review-img-wrap v2-mt-0-5'>
                        <img src={imgStar} width={130} />
                    </div>
                </div>
            </Col>

            <Col md={4} sm={6} className='v2-tp-mt-6 v2-tp-w-6 v2-m-w-12'>
                <div className='text-center v3-review-wrap'>
                    <div className='v3-review-img'>
                        <img src={imgAnton} />
                    </div>
                    <p className='v3-review-text poppins v2-font-14 light dark-text-3 v2-mt-1-5'>We checked out of banking and the man- And decided to join govato part time</p>
                    <p className='v3-review-name poppins v2-font-14 bold dark-text-5 v2-mb-0'>Jessica and Antoinette</p>
                    <div className='img-wrap v3-review-img-wrap v2-mt-0-5'>
                        <img src={imgStar} width={130} />
                    </div>
                </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
export default withTranslation()(section3);