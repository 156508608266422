import React from 'react'

const TermsBox = ({ num, year, percent, example }) => {
  return (
    <div className='s3-2-box s5-2-box'>
        <p className='s3-2-box-num s5-2-box-num'><span>{num}</span></p>
        <h4 className='s3-2-box-year'>{year}</h4>
        <div className='s3-2-box-divider s5-2-box-divider'></div>
        <p className='s3-2-box-percent'>{percent}</p>
        <p className='text-center v2-font-13 s5-2-box-example'>Example</p>
        <p className='s3-2-box-ex'>{example}</p>
    </div>
  )
}

export default TermsBox