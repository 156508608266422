import React, { Component } from 'react';
import { Row, Col, Input, Label, Button, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { deviceDetect, EXPLORER_URL, WALLET_URL } from '../constants/defaultValues';
import { Service } from '../providers/Services';
import { ToastContainer, toast } from 'react-toastify';
import DaoComingSoonModal from './DaoComingSoonModal';

// Helpers
import { withAffCodeURL } from '../helpers/helpers';

// Popups
import RelaunchingPopup from '../components/popup/RelaunchingPopup';
import FloatingIcons from '../components/floating-icons/FloatingIcons';

// CSS
import '../assets/css/NewBottomNav3.css'

// Images 
import ImgBitovation from '../assets/img/bottom-nav-3/logo-bitovation.png'
import ImgGovato from '../assets/img/bottom-nav-3/logo-govato.png'
import ImgOtext from '../assets/img/bottom-nav-3/logo-otext.png'
import ImgPolygon from '../assets/img/bottom-nav-3/logo-polygon.png'
import ImgOpenSea from '../assets/img/bottom-nav-3/logo-opensea.png'
import ImgOvato from '../assets/img/bottom-nav-3/img-ovato.png'

const toaststyle = {
  position: 'top-right',
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};
class NewBottomNav3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isInvalid: '',
      showDaoModal: false,
      modal: false,
      copyright: ''
    };
  }
  toggleDaoModal = (e, value) => {
    e.preventDefault();
        this.setState({ showDaoModal: value })
    }
  handleInput = (e) => {
    this.setState({
      isInvalid: false,
      [e.target.name]: e.target.value,
    });
  };
  componentDidMount() { 
    Service.getCopyright()
    .then(res=>{
      this.setState({copyright:res.msg})
    })
   }
  handleSubmit = () => {
    if (this.state.email === '') {
      toast.error("Email can't be empty !", toaststyle);
      this.setState({ isInvalid: true });
    } else {
      this.setState({ isInvalid: false });
      const payload = {
        email: this.state.email,
        os: deviceDetect.os,
        browser: deviceDetect.browsername,
        device: deviceDetect.devicetype,
        plateform: deviceDetect.plateform,
        type: 'home',
      };
      Service.subscribeForm(payload)
        .then((res) => {
          this.setState({ email: '' });
          toast.success('Successfully subscribed!', toaststyle);
        })
        .catch((error) => {
          if (error.status === 400) {
            toast.error(
              'Email is invalid, please enter a valid email!',
              toaststyle
            );
          } else {
            toast.error(
              error && error.response
                ? error.response.data.message
                : 'Some error occurred!',
              toaststyle
            );
          }
        });
    }
  };
  render() {
    const { t } = this.props;

    const toggleModal = (e) => {
      e.preventDefault();
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    return (
      <>
      <div className='bottom-nav-3'>

        <ToastContainer
          position='top-right'
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />

        <div className='bottom_main new-bottom-main'>
          <Container className='footer-container-1'>
            <Row className='bottom_main_row'>
              {/* COMPANY COLUMN */}
              <Col>
                <span className='bottom_main_head'>
                  General
                </span>
                <ul className='list-unstyled mt-3 bottom_list'>
                  <li>
                    <a href={withAffCodeURL('about')}>{t('bottombar.about')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('media')}>{t('bottombar.media')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('contact')}>{t('bottombar.contact_us')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('wallet')}>{t('bottombar.get_started')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('yellowpaper')}>{t('bottombar.technology')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('support')}>{t('bottombar.support')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('exchange')}>{t('bottombar.exchanges')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('merchant')}>{t('newFooter.become')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('wallet')}>{t('newFooter.get')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('financial-overview-and-roadmap-2024')}>Financial Overview</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('faqs')}>FAQs</a>
                  </li>
                </ul>
              </Col>
              {/* LEARN MORE COLUMN */}
              <Col>
                <span className='bottom_main_head'>
                  {t('bottombar.learn_more')}
                </span>
                <ul className='list-unstyled mt-3 bottom_list'>
                  <li>
                    <a href={withAffCodeURL('press')}>Press</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer">
                      {t('bottombar.application')}
                    </a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('whitepaper')}>{t('bottombar.white')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('https://wallet.ovato.com')} target='_blank' rel="noopener noreferrer">{t('bottombar.explore')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('brand-assets')}>{t('bottombar.brand')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('stacking')}>{t('bottombar.stack')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('media')}>{t('newFooter.media')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('support')}>{t('newFooter.support')}</a>
                  </li>
                  <li>
                    <a href={withAffCodeURL('bug-bounty')}>{t('newFooter.report')}</a>
                  </li>
                </ul>
              </Col>
              {/* Connect With Us Column */}
              <Col>
                <span className='bottom_main_head'>
                    Community
                </span>
                <ul className='list-unstyled mt-3 bottom_list'>
                  <li>
                    {' '}
                    <a
                      href='https://www.facebook.com/OvatoCoin'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {t('bottombar.facebook')}
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      rel='noopener noreferrer'
                      href='https://www.youtube.com/watch?v=QvFtAtVnSik'
                      target='_blank'
                    >
                      {t('bottombar.youtube')}
                    </a>
                  </li>
                  <li>
                    <a
                      rel='noopener noreferrer'
                      href='https://www.linkedin.com/company/ovato/mycompany/?viewAsMember=true'
                      target='_blank'
                    >
                      {t('bottombar.linkedin')}
                    </a>
                  </li>
                  <li>
                    <a
                      rel='noopener noreferrer'
                      href='https://x.com/OvatoCoin'
                      target='_blank'
                    >
                      <svg 
                          viewBox="0 0 24 24" 
                          width="16px" 
                          height="16px" 
                          fill="white" 
                          aria-hidden="true" 
                          class="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-lrsllp r-1nao33i r-16y2uox r-8kz0gk"
                      >
                          <g>
                              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                          </g>
                      </svg>
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      rel='noopener noreferrer'
                      href='https://www.instagram.com/ovatocoin/'
                      target='_blank'
                    >
                      {t('bottombar.instagram')}
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      rel='noopener noreferrer'
                      href='https://github.com/OvatoToken/Ovato'
                      target='_blank'
                    >
                     Github
                    </a>
                  </li>
                  <li>
                    <a
                      href={withAffCodeURL('meet-ups')}
                    >
                      Meet Ups
                    </a>
                  </li>
                  <li>
                    <a
                      rel='noopener noreferrer'
                      href={'https://t.me/+6-aAu6xipqFmOGY5'}
                      target='_blank'
                    >
                      Telegram
                    </a>
                  </li>
                </ul>
              </Col>
              {/* Connect With Us Column */}
              {/* Industry Solutions & Govato */}
              <Col>
                <span className='bottom_main_head'>
                    Industry Solutions
                </span>

                <ul className='list-unstyled mt-3 bottom_list'>
                  <li><a href={withAffCodeURL('dispensary')}>Cannabis</a></li>
                  <li><a href={withAffCodeURL('escorts')}>Entertainers</a></li>
                  <li><a href={withAffCodeURL('unbanked')}>Unbanked</a></li>
                  <li><a href='#'>New Immigrants</a></li>
                  <li><a href='#'>Overview</a></li>
                </ul>

                <span className='v2-mt-3 v2-mb-0 bottom_main_head'>
                    Govato
                </span>

                <ul className='list-unstyled mt-3 bottom_list'>
                  <li><a href={withAffCodeURL('leaderboard')}>Leaderboard</a></li>
                  <li><a href='#'>About Premium</a></li>
                  <li><a href={withAffCodeURL('nfts')}>NFTs</a></li>
                </ul>
              </Col>
              {/* Connect With Us Column */}
              <Col>
                <span className='bottom_main_head'>
                  Partners
                </span>
                <ul className='list-unstyled mt-3 bottom_list footer-v3-partners'>
                  <li><a href='https://bitovation.com/' target='_blank' rel="noopener noreferrer"><img width={87} src={ImgBitovation} /></a></li>
                  <li><a href='https://govato.io/' target='_blank' rel="noopener noreferrer"><img width={75} src={ImgGovato} /></a></li>
                  <li><a href='http://otext.io/' target='_blank' rel="noopener noreferrer"><img width={56} src={ImgOtext} /></a></li>
                  <li><a href='https://mumbai.polygonscan.com/' target='_blank' rel="noopener noreferrer"><img width={86} src={ImgPolygon} /></a></li>
                  <li><a href='https://opensea.io/' target='_blank' rel="noopener noreferrer"><img width={87} src={ImgOpenSea} /></a></li>
                </ul>
              </Col>
              {/* Join our newsletter col */}
              <Col className='text-center new-footer-join-newsletter-col'>
                <a href='/'>
                  <img
                    alt='Ovato RGB'
                    src={ImgOvato}
                    className='footer-v3-logo' 
                    width={200}
                  />
                </a>
                <Label className='v2-mt-2' style={{ fontWeight: 'bold', fontSize: 19 }}>
                  {t('bottombar.join_our_newsletter')}
                </Label>
                <center className='v2-mt-0-5'>
                  <Input
                    type='email'
                    name='email'
                    value={this.state.email}
                    placeholder={t('bottombar.email')}
                    className='bottom_input footer-email-input'
                    style={{
                      borderRadius: 60,
                      height: 50,
                      border: '1px solid white',
                      color: 'white',
                      paddingLeft: '20px',
                    }}
                    required
                    invalid={this.state.isInvalid}
                    onChange={this.handleInput}
                  />
                </center>
                <Button
                  className='bottom_subscribe'
                  style={{ borderRadius: 60 }}
                  onClick={this.handleSubmit}
                >
                  {t('bottombar.subscribe_now')}
                </Button>
                <div className='v2-mt-1 bottom-nav-download-wrap'>
                  <a
                    //href='https://play.google.com/store/apps/details?id=com.ovato.wallet'
                    href='#'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={(e) => toggleModal(e)}
                  >
                    <img
                      alt='App Store'
                      className='bottom_storeicon'
                      src={require('../assets/img/img-app-store-footer.png')}
                      width={50} 
                    />
                  </a>
                  &nbsp;
                  <a
                    // href='https://apps.apple.com/us/app/ovato-wallet/id1520107226'
                    href='#'
                    target='_blank'
                    rel='noopener noreferrer' 
                    onClick={(e) => toggleModal(e)}
                  >
                    <img
                      alt='Google Play'
                      className='bottom_storeicon'
                      src={require('../assets/img/img-google-play-footer.png')} 
                      width={50} 
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
          <div className='text-center mt-5 footer-container-2'>
            <p className='bottom_info_text desktop'>
              <a href={withAffCodeURL('privacy-policy')}>{t('bottombar.privacy_policy')}</a>
              &emsp;|&emsp;
              <a href={withAffCodeURL('terms-and-condition')}>
                {t('bottombar.terms_and_condition')}
              </a>
              &emsp;|&emsp; Copyright © {new Date().getFullYear()} Ovato &emsp;|&emsp;
              {t('bottombar.all_right_reserve')}
            </p>
            <p className='bottom_info_text mobile'>
                <span><a href={withAffCodeURL('privacy-policy')}>{t('bottombar.privacy_policy')}</a></span>
              <span><a href={withAffCodeURL('terms-and-condition')}>
                {t('bottombar.terms_and_condition')}
              </a></span>
              <span>Copyright © {new Date().getFullYear()} Ovato</span>
              <span>{t('bottombar.all_right_reserve')}</span>
            </p>
          </div>
        </div>
      </div>
      {this.state.showDaoModal && <DaoComingSoonModal show={this.state.showDaoModal}  toggleDaoModal={this.toggleDaoModal} />}

      {/* Floating Social Icons */}
      <FloatingIcons />
      
      {/* Modals */}
      <RelaunchingPopup toggle={toggleModal} modal={this.state.modal} />
      </>
    );
  }
}
export default withTranslation()(NewBottomNav3);
