import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import LeaderboardTopBox from './LeaderboardTopBox';

import Img1 from '../../assets/img/leaderboard-top-5/img-top-1.jpg';
import Img2 from '../../assets/img/leaderboard-top-5/img-top-2.jpg';
import Img3 from '../../assets/img/leaderboard-top-5/img-top-3.jpg';
import Img4 from '../../assets/img/leaderboard-top-5/img-top-4.jpg';
import Img5 from '../../assets/img/leaderboard-top-5/img-top-5.jpg';

class LeaderboardTop5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }
  render() {
    const { t, data, loading } = this.props;
    
    const image = [
        Img1,
        Img2,
        Img3,
        Img4,
        Img5
    ]

    if (!data || loading) {
        return (
            <div className='text-center'>Loading...</div>
        )
    }

    return (
        <div className='leaderboard-top-5-wrap'>

            { data.map((item, index) => (
                <div className='leaderboard-top-5-col'>
                    <div className='leaderboard-top-5-col-inner'>
                        <LeaderboardTopBox data={item} image={image[index]} />
                    </div>
                </div>
            )) }

        </div>
    );
  }
}
export default withTranslation()(LeaderboardTop5);