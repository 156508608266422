import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// Helpers
import { withAffCodeURL } from '../helpers/helpers';

// CSS
import '../assets/css/FinancialOverview.css';
import '../assets/css/FinancialOverview2.css';

// Images
import imgOvato from '../assets/img/financial-overview/img-ovato.png'
import imgRoadMap from '../assets/img/financial-overview/img-roadmap.png'
import imgQ1 from '../assets/img/financial-overview/img-q1.png'
import imgQ2 from '../assets/img/financial-overview/img-q2.png'
import imgQ3 from '../assets/img/financial-overview/img-q3.png'
import imgQ4 from '../assets/img/financial-overview/img-q4.png'
import imgDAO from '../assets/img/financial-overview/img-ovato-dao.png'
import imgOutlook from '../assets/img/financial-overview/img-outlook.png'
import imgVideo from '../assets/img/financial-overview-2/img-video.jpg'
import QRFinancialOverview from '../components/qrcode/QRFinancialOverview';

import IconYoutube from '../assets/img/financial-overview-2/icon-youtube.png'
import IconLinkedIn from '../assets/img/financial-overview-2/icon-linked-in.png'
import IconTwitter from '../assets/img/financial-overview-2/icon-twitter.png'
import IconFacebook from '../assets/img/financial-overview-2/icon-facebook.png'
import IconInstagram from '../assets/img/financial-overview-2/icon-instagram.png'
import IconGit from '../assets/img/financial-overview-2/icon-git.png'

import ImgUtilityLeft from '../assets/img/financial-overview-2/img-utility-left.png'
import ImgUtilityRight from '../assets/img/financial-overview-2/img-utility-right.png'

import imgSpecificVerticals from '../assets/img/financial-overview-2/img-specific-verticals.png'
import imgTwoPronged from '../assets/img/financial-overview-2/img-two-pronged.png'
import imgForMerchants from '../assets/img/financial-overview-2/img-for-merchants.png'

import ImgInfluencerArmy from '../assets/img/financial-overview-2/img-influencer-army.png'
import ImgInfluencer01 from '../assets/img/financial-overview-2/img-influencer-01.png'
import ImgInfluencer02 from '../assets/img/financial-overview-2/img-influencer-02.png'
import ImgBitovationSolution from '../assets/img/financial-overview-2/img-bitovation-solution.png'

import ImgDivider from '../assets/img/financial-overview-2/img-divider-img.png'
import Fancybox from '../containers/FancyBox';

// Components

class FinancialOverview2 extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='financial-main financial-main-2'>     

        {/* Section 1*/}
        <section className='v2-pb-18 v2-pt-6 v2-m-pb-16 v2-m-pt-4 financial-1'>
            <Container>
                <Row>
                    <Col>
                        <div className='v2-ml--2 v2-m-ml-0 v2-mb--1 v2-tp-text-center img-wrap'>
                            <a href={withAffCodeURL('/')}><img className='financial-1-img' width={129} src={imgOvato} /></a>
                        </div>
                        <h1 className='mt-0 poppins v2-font-52 v2-tl-font-48 v2-tp-font-40 v2-m-font-28 semi-bold white-text lh-1-2 mb-0 v2-tp-text-center v2-m-mt-2'>Financial Overview</h1>
                        <p className='poppins v2-font-39 v2-tl-font-28 v2-m-font-22 v2-m-mt-0-5 semi-bold white-text lh-1-2 v2-tp-text-center'>and Roadmap 2024/2025</p>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 2*/}
        <section className='v2-mt--1 v2-pb-0 financial-2 v2-tl-pb-4 v2-m-pt-3'>
            <Container className='relative z-index-2'>
                <Row>
                    <Col sm={8} className='v2-tp-col-12 v2-pr-4 v2-m-pr-1'>
                        <div className='v2-mt--14 video-wrap v2-tp-text-center fo-2-video-wrap'>
                            <Fancybox>
                                <a data-fancybox href='https://www.youtube.com/watch?v=nqmiBOejUYc'>
                                    <img className='new-home-4-video' src={imgVideo} />
                                </a>
                            </Fancybox>
                        </div>

                        <h2 className='poppins v2-font-29 v2-tp-font-32 v2-m-font-28 bold dark-text-5 v2-tp-text-center v2-mt-3'>Overview</h2>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-m-text-left'>Welcome to Ovato, the next generation of digital currency for people who believe there is an alternative, secure way to store, exchange, and earn value. Ovato provides real-world solutions to encourage and incentivize both merchants and users to use our currency. We are growing our community of like-minded individuals, and our commitment is to provide a better, more profitable solution to exchange value that is secure, safe, and decentralized.</p>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-m-text-left'>Ovato coin syncs elegantly across all of its platforms, including coins, merchants, user wallets, and NFTs. Ovato is better for users because it is appreciable, allowing users to earn on transactions while acting as their fiduciary. Ovato is also better for merchants, enabling them to save up to 85% on merchant processing. It also provides merchants the ability to earn additional revenue streams and greater security.</p>

                        <div className='btn-wrap v2-mt-2 v2-tp-text-center'>
                            <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer" className='fo-btn fo-btn-2'>Get a basic wallet</a>
                        </div>
                    </Col>

                    <Col sm={4} className='v2-tp-col-12'>
                        <div className='v2-mt--6 v2-tp-mt-3 financial-white-box'>
                            <div className='financial-text-wrap'>
                                <div className='text-1'>online</div> 
                                <div className='text-2'>ovato.com</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>symbol</div> 
                                <div className='text-2'>OVATO</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>maximum outstanding</div> 
                                <div className='text-2'>950 million</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>current outstanding</div> 
                                <div className='text-2'>344.4 million</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>public float</div> 
                                <div className='text-2'>15 million</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>public float</div> 
                                <div className='text-2'>3 million</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>stack/locking</div> 
                                <div className='text-2'>75 million</div>
                            </div>

                            <div className='financial-text-wrap'>
                                <div className='text-1'>general lock</div> 
                                <div className='text-2'>200 million</div>
                            </div>

                            <div className='v2-mt-1 financial-text-wrap financial-text-wrap-2'>
                                <div className='text-1'>Coin Concentration Link</div>
                                <div className='text-1'><a href={withAffCodeURL('https://ovato.com/en/top-100-holders')} target='_blank'>https://ovato.com/en/top-100-holders</a></div>
                            </div>

                            <div className='btn-wrap v2-mt-2 v2-mb-1 text-center v2-tp-text-center'>
                                <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer" className='fo-btn fo-btn-2'>Get a basic wallet</a>
                            </div>
                            
                        </div>

                        <div className='v2-mt-2 financial-white-box financial-white-box-2'>
                            <h3 className='poppins v2-font-22 v2-m-font-20 bold dark-text-5'>Get Started</h3>

                            <ul className='financial-ul'>
                                <li>Get a Wallet</li>
                                <li>Receive some money </li>
                                <li>Discover the Opportunities </li>
                            </ul>

                            <h3 className='poppins v2-font-16 medium dark-text-5 text-center financial-connect'>
                                <span>Connect with Us</span>
                            </h3>

                            <ul className='financial-social'>
                                <li><a href='https://www.youtube.com/watch?v=QvFtAtVnSik' target="_blank"><img src={IconYoutube} /></a></li>
                                <li><a href='https://www.linkedin.com/company/ovato/mycompany/?viewAsMember=true' target="_blank"><img src={IconLinkedIn} /></a></li>
                                <li><a href='https://www.facebook.com/OvatoCoin' target="_blank"><img src={IconFacebook} /></a></li>
                                <li><a href='https://x.com/OvatoCoin' target="_blank">
                                    <svg 
                                        viewBox="0 0 24 24" 
                                        width="30px" 
                                        height="30px" 
                                        fill="black" 
                                        aria-hidden="true" 
                                        class="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-lrsllp r-1nao33i r-16y2uox r-8kz0gk"
                                    >
                                        <g>
                                            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                                        </g>
                                    </svg></a>
                                </li>
                                <li><a href='https://www.instagram.com/ovatocoin/' target="_blank"><img src={IconInstagram} /></a></li>
                                <li><a href='https://github.com/OvatoToken/Ovato' target="_blank"><img src={IconGit} /></a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 3 */}
        <section className='v2-financial-3 v2-pt-16 v2-tl-pt-10 v2-tp-pt-8 v2-m-pt-6 v2-pb-8 v2-tl-pb-6 v2-tp-pb-4'>
            <Container>
                <Row className='align-center'>
                    <Col sm={3}>
                        <div className='text-center img-wrap'>
                            <img src={ImgUtilityLeft} />
                        </div>
                    </Col>
                    <Col className='v2-pl-2 v2-pr-2' sm={6}>
                        <h2 class="poppins v2-font-36 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold dark-text-5 text-center v2-tp-text-center">Utility is the Core and Full Circle is our Philosophy</h2>

                        <p class="poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 text-center v2-m-text-left">Our philosophy is to provide ways for users to utilize Ovato for multiple use cases that exist in the real world. To that end, Ovato has built secure payment solutions on the blockchain, operating as a layer 2 backed by the $4 billion Polygon network.</p>

                        <p class="poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 text-center  v2-m-text-left">We believe that “real-world” utility is fundamental to establishing a bedrock of “real users” in marketplaces. To that end, we have developed a suite of products that solve particular use cases and provide economic incentives and efficiencies for those who use them.</p>

                        <p class="poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 text-center  v2-m-text-left">Additionally, we believe there is a growing marketplace for users who have a real desire to embrace new, alternative ways to store, exchange, and earn value outside centralized systems.</p>
                    </Col>
                    <Col sm={3}>
                        <div className='text-center img-wrap v2-m-mt-2'>
                            <img className='v2-m-mw-70' src={ImgUtilityRight} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 3 2 */}
        <section className='v2-financial-3-2 v2-pt-1 v2-tl-pt-10 v2-tp-pt-2 v2-m-pt-4 v2-pb-8 v2-tl-pb-6 v2-tp-pb-4'>
            <Container>
                <Row className='v2-mt-6 v2-m-mt-0 align-center'>
                    <Col sm={6} className='v2-pr-4 v2-tp-col-12 v2-m-pr-1'>
                        <div className='text-center img-wrap v2-tp-mw-50 mx-auto v2-m-mw-100'>
                            <img width={321} src={imgTwoPronged} />
                        </div>
                    </Col>

                    <Col sm={6} className='v2-tp-col-12 v2-tp-mt-4 v2-m-mt-3'>
                        <h2 className='poppins v2-font-36 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold dark-text-5 v2-tp-text-center'>Two-Pronged Approach</h2>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 v2-m-text-left'>Ovato takes an independent, two-pronged approach for both merchants and users, allowing community development to work and grow independently from each other. While mutually exclusive, they sync elegantly, as a currency should. For merchants, the emphasis is on helping their business become more profitable.</p>
                    </Col>
                </Row>

                <Row className='v2-mt-6 v2-m-mt-4 align-center'>
                    <Col sm={6} className='v2-tp-col-12 v2-tp-order-1 v2-tp-mt-2'>
                        <h2 className='poppins v2-font-36 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold dark-text-5 v2-tp-text-cente v2-m-mt-1'>For Merchants</h2>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 v2-m-text-left'>Firstly, the Ovato merchant wallet allows businesses to not only use Ovato but also take full advantage of the technology, building on this new frontier of doing business, from marketing to real-world CRM solutions. The Govato platform, along with the NFT solution, provides a legitimate income opportunity for those who want to embrace this new way of exchanging value.</p>
                    </Col>

                    <Col sm={6} className='v2-tp-col-12'>
                        <div className='text-center img-wrap mx-auto v2-tp-mw-50 v2-m-mw-80'>
                            <img width={341} src={imgForMerchants} />
                        </div>
                    </Col>
                </Row>

                <Row className='v2-mt-6 v2-m-mt-4 align-center'>
                    <Col sm={6} className='v2-pr-4 v2-tp-col-12'>
                        <div className='text-center img-wrap v2-tp-mw-50 mx-auto v2-m-mw-100'>
                            <img width={421} src={imgSpecificVerticals} />
                        </div>
                    </Col>

                    <Col sm={6} className='v2-tp-col-12 v2-tp-mt-4 v2-m-mt-3'>
                        <h2 className='poppins v2-font-36 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold dark-text-5 v2-tp-text-center'>Specific Verticals: Merchant Wallets</h2>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 v2-m-text-left'>Ovato believes the key to actual mass adoption is fulfilling a need rather than trying to create one. For example, the cannabis dispensary business in the USA is now a $15 billion dollar industry since becoming legal in 37 states. However, banking is still illegal at the federal level, and every bank must adhere to federal banking laws to maintain its charter. This means that dispensaries in any state are restricted to using cash only and are denied access to traditional banking systems. This creates a massive problem for the entire cannabis industry.</p>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 v2-m-text-left'>To that end, Ovato has built a proprietary process that provides a legal platform to fix this issue by accepting Ovato as a method of payment. This offers a legal solution for dispensary owners, removing the need for workarounds that create more problems than solutions simply to accept payments for their legal businesses.</p>
                    </Col>
                </Row>

                <Row className='v2-mt-6 v2-m-mt-4'>
                    <Col>
                        <div className='img-wrap'>
                            <img src={ImgDivider} />
                        </div>
                        <p className='v2-mw-70 v2-m-mw-100 mx-auto v2-mt-0 poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 text-center'>Additionally, Ovato has identified 26 specific industries that are either not bankable or are deemed extremely “high risk” for various reasons. For many of these industries, banking may be legal but economically impractical due to credit card and processing fees ranging from 9% to 18%. Ovato has built unique solutions that not only fix these inefficiencies but also provide additional revenue streams by onboarding their existing customer base and peers.</p>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 4 */}
        <section className='financial-4 v2-financial-4 v2-pt-16 v2-tl-pt-14 v2-pb-8 v2-tp-pb-6 v2-m-pb-4 v2-tp-pt-12 v2-m-pt-6'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='poppins v2-font-24 medium white-text text-center ls-4 text-uppercase'>For Users and the</h2>
                        <div className='text-center img-wrap'>
                            <img width={475} className='v2-m-mw-80' src={ImgInfluencerArmy} />
                        </div>
                    </Col>
                </Row>

                <Row className='v2-mt-4 align-center'>
                    <Col sm={6} className='v2-pr-4 v2-tp-col-12 v2-m-pr-1'>
                        <div className='img-wrap v2-tp-mw-50 mx-auto v2-m-mw-70 v2-m-mw-100'>
                            <img src={ImgInfluencer01} />
                        </div>
                    </Col>

                    <Col sm={6} className='v2-tp-col-12 v2-tp-mt-3 v2-m-mt-0'>
                        <p className='poppins v2-font-15 light white-text lh-1-8 v2-mt-1-5 v2-m-text-left'>Ovato is growing an influencer army called Govato (govato.io), to spread the word and accelerate mass adoption of its wallets and user base. These influencers come from around the world. Govato provides a forum where influencers can buy into a dealership, offering an opportunity to earn Ovato for various actions. This dealership is represented by an NFT that holds coins along with income streams that provide ongoing, perpetual income within the NFT. These NFTs are limited in supply, and holders can sell them as complete dealerships or extract part or all of the coins to sell on exchanges. Influencers are also incentivized to bring in merchants and other influencers, earning from their activities as well. The compensation plan is handled entirely within the Ovato ecosystem. The Govato army is provided with a suite of tools to help them grow their business, including trackable links, daily content, and other resources to expand the network.</p>
                    </Col>
                </Row>

                <Row className='v2-mt-4 align-center'>
                    <Col sm={6} className='v2-tp-col-12 v2-tp-order-1 v2-tp-mt-3'>
                        <p className='poppins v2-font-15 light white-text lh-1-8 v2-mt-1-5 v2-m-mt-0 v2-m-text-left'>The compensation plan is all done though the Ovato ecosystem. The Govato army is provided with suite of tools to help theme  grow there business ranging from trackable links to daily content to help grow the network.</p>

                        <p className='poppins v2-font-15 light white-text lh-1-8 v2-mt-1-5 v2-m-text-left'>The influencer army includes individuals from across all verticals, from industry specialists to professional networkers. The Govato platform, combined with the NFT solution, provides a legitimate income opportunity for those ready to embrace this new way of exchanging value.</p>
                    </Col>

                    <Col sm={6} className='v2-tp-col-12'>
                        <div className=' v2-pl-4 img-wrap v2-mr--4 v2-tl-mr-0 v2-tp-mw-50 v2-m-mw-80 mx-auto v2-tp-pl-0'>
                            <img width={376} src={ImgInfluencer02} />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className='text-center btn-wrap v2-mt-6 v2-m-mt-4'>
                            <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer" className='fo-btn v2-fo-btn-3'>Get basic wallet</a>
                        </div>
                    </Col>
                </Row>

                <Row className='v2-mt-10 v2-tp-mt-6 v2-m-mt-4'>
                    <Col>
                        <div className='v2-fo-separator'></div>
                    </Col>
                </Row>

                <Row className='v2-mt-8 align-center v2-m-mt-4'>
                    <Col sm={6} className='v2-pr-4 v2-tp-col-12 v2-m-pr-1'>
                        <div className='img-wrap v2-tp-mw-50 mx-auto v2-m-mw-80'>
                            <img src={ImgBitovationSolution} />
                        </div>
                    </Col>

                    <Col sm={6} className='v2-tp-col-12 v2-tp-mt-3'>
                        <h2 className='poppins v2-font-36 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold white-text v2-tp-text-center'>Bitovation Solution</h2>

                        <p className='poppins v2-font-15 light white-text lh-1-8 v2-mt-1-5 v2-m-text-left'>Bitovation is a technology company offering software solutions to various industries. Powered by the Ovato coin, Bitovation provides the technology to help its industry partners onboard to the crypto world using Ovato as its gateway. As a strategic stakeholder, Bitovation offers a suite of software products and services to the industry. For more information on Bitovation, visit bitovation.com.</p>

                        <div class="btn-wrap v2-mt-2 v2-tp-text-center"><a href="https://wallet.ovato.com/" target="_blank" rel="noopener noreferrer" class="fo-btn fo-btn-2">Get a basic wallet</a></div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 5 */}
        <section className='financial-5'>
            <Container>
                <Row>
                    <Col className='financial-5-col-1'>
                        <h2 className='relative z-index-2 v2-mt-6 poppins v2-font-56 v2-tl-font-48 v2-tp-font-36 v2-m-font-32 bold blue-text-7 v2-pl-6 v2-tp-pl-2 v2-m-pl-0 v2-m-text-left v2-m-mw-50 v2-m-mt-4'>Roadmap 2024/2025</h2>

                        <div className='img-wrap financial-5-img-step desktop'>
                            <img src={imgRoadMap} />
                        </div>

                        {/* Q1 */}
                        <div className='financial-step-wrap fsw-1'>
                            <ul className='financial-step-ul'>
                                <li>Fork Completed</li>
                                <li>Basic Wallet Launched </li>
                                <li>Fork Coins distributed to community</li>
                            </ul>

                            <div className='img-wrap'>
                                <img src={imgQ2} />
                            </div>
                        </div>

                        {/* Q2 */}
                        <div className='financial-step-wrap fsw-2'>
                            <ul className='financial-step-ul'>
                                <li>Launch Premium Wallets </li>
                                <li>Launch Lot A - NFTs</li>
                                <li>Launch Affiliate Army Govato</li>
                                <li>Launch community and ongoing training and support for Influencers</li>
                            </ul>

                            <div className='img-wrap'>
                                <img src={imgQ3} />
                            </div>
                        </div>

                        {/* Q3 */}
                        <div className='financial-step-wrap fsw-3'>
                            <ul className='financial-step-ul'>
                                <li>Re- trading Bank</li>
                                <li>Re-Trading Profit</li>
                                <li>List Premium Wallet NFTs on OpenSea</li>
                                <li>Re Launch Apps in app store</li>
                            </ul>

                            <div className='img-wrap'>
                                <img src={imgQ4} />
                            </div>
                        </div>

                        {/* Q4 */}
                        <div className='financial-step-wrap fsw-4'>
                            <ul className='financial-step-ul'>
                                <li>Launch coin with Bitovation MSP services</li>
                                <li>Begin trading Ovato Coin and NFTs Binance</li>
                                <li>Launch celebrity influencer</li>
                                <li>Launch Ovato DAO</li>
                            </ul>

                            <div className='img-wrap'>
                                <img src={imgQ1} />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='v2-mt-12 v2-m-mt-4'>
                    <Col>
                        <div className='text-center btn-wrap'>
                            <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer" className='fo-btn fo-btn-2'>Get a basic wallet</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 6 */}
        <section className='v2-pt-8 v2-tp-pt-4 financial-6 v2-pb-8 v2-tl-pb-6 v2-tp-pb-4'>
            <Container>
                <Row className='align-center'>
                    <Col sm={7} className='v2-tp-col-12 v2-tp-order-1 v2-tp-mt-2'>
                        <h2 className='poppins v2-font-36 v2-tl-font-32 v2-tp-font-28 v2-m-font-24 bold dark-text-5 v2-tp-text-center'>Ovato Dao</h2>
                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 v2-m-text-left'>Ovato has created a DAO (Decentralized Autonomous Organization) to maintain the network and ensure long-term sustainability. Ovato charges users a 1% transaction fee on both the buy-side and sell-side transactions for a total network fee of 2%. This fee is spread across the network and fully administered by the community (DAO), fueling the network while providing continuing royalties for early adopters and affiliates.</p>

                        <p className='poppins v2-font-15 light dark-text-3 lh-1-8 v2-mt-1-5 v2-m-text-left'>Ovato is pioneering a new governance model through its DAO, empowering Ovato coin holders to participate in decision-making regarding the future development and direction of the cryptocurrency. Approximately 1% of these transaction fees will be allocated to climate change initiatives. Through smart contracts and blockchain technology, Ovato’s DAO ensures transparent and decentralized decision-making, allowing stakeholders to vote on various initiatives like protocol upgrades, marketing strategies, and community efforts. This democratic structure fosters engagement and enhances the utility and value of Ovato. By aligning with the principles of transparency, inclusivity, and innovation, Ovato is leading a new model of decentralized governance in the cryptocurrency space.</p>
                    </Col>

                    <Col sm={5} className='v2-tp-col-12'>
                        <div className='img-wrap v2-mr--12 v2-tl-mr-0 v2-tp-mw-50 v2-m-mw-90 v2-tp-mx-auto'>
                            <img src={imgDAO} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 7 */}
        <section className='v2-pt-14 v2-tl-pt-14 v2-tp-pt-12 v2-m-pt-6 v2-pb-8 v2-tl-pb-6 v2-tp-pb-4 v2-m-pb-3 financial-7'>
            <Container>
                <Row className='align-center'>
                    <Col sm={5} className='v2-tp-col-12'>
                        <div className='img-wrap v2-tp-mw-50 v2-m-mw-70 mx-auto'>
                            <img src={imgOutlook} />
                        </div>
                    </Col>

                    <Col sm={7} className='v2-pl-6 v2-tp-col-12 v2-tp-pl-2 v2-tp-mt-2'>
                        <h2 className='poppins v2-font-36 v2-tl-font-32 v2-m-font-28 bold white-text v2-tp-text-center'>Outlook</h2>
                        <p className='poppins v2-font-15 light white-text lh-1-8 v2-mt-1-5 v2-m-text-left'>We are extremely confident that Ovato can provide real utility and acceptance of our coin and offer a different approach to all those who use it in their daily lives. By addressing actual, real life needs and providing tangible economic incentives for all users, Ovato can focus its energy on transactions and fulfill the citizens' present desire for an alternative means of exchanging and storing value. Ovato has plans to list our NFTs on more mature exchanges, such as Binance and OpenSea.</p>

                        <div className='v2-mt--2 v2-tp-text-center v2-justify-content-center v2-m-flex-column flex align-center v2-mt-2 btn-wrap'>
                            <a href={withAffCodeURL('https://wallet.ovato.com/')} target='_blank' rel="noopener noreferrer" className='fo-btn'>Get a free wallet</a>
                            <div className='v2-mt--1 v2-ml--2 v2-m-ml-0'>
                                <QRFinancialOverview url={withAffCodeURL('https://wallet.ovato.com/') } size={600} />
                            </div>
                            {/* <img src={imgScan} width={200} className='v2-mt--2 v2-m-mt-0 v2-ml--2 v2-m-ml-0 desktop' />
                            <img src={imgScanMobile} width={150} className='mobile v2-mt--1 v2-ml-3' /> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

      </div>
    );
  }
}
export default withTranslation()(FinancialOverview2);
