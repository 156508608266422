import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

// CSS
import '../assets/css/BusinessInABox.css'

// Images
import OvatoLogo from '../assets/img/business-in-a-box/logo-ovato.png'
import IconStar from '../assets/img/business-in-a-box/icon-star.png'
import OvatoWallet from '../assets/img/business-in-a-box/img-ovato-wallet.png'

import ImgIncome from '../assets/img/business-in-a-box/img-income.png'
import ImgRoyalties from '../assets/img/business-in-a-box/img-royalties.png'
import ImgExit from '../assets/img/business-in-a-box/img-exit.png'
import ImgTools from '../assets/img/business-in-a-box/img-tools.png'
import ImgTradable from '../assets/img/business-in-a-box/img-tradable-markets.png'
import BusinessVideo from '../components/video/BusinessVideo';
import { withAffCodeURL } from '../helpers/helpers';

import { WALLET_URL } from '../constants/defaultValues'

class BusinessInABox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className='business-main'>

            <div className='business-header'>
                <Container className='relative'>
                    <Row>
                        <Col>
                            <p className='poppins p-1 v2-font-22 v2-tl-font-20 v2-tp-font-18 ls-3 bold white-text text-center text-uppercase m-0'>Easiest Way To <span className='orange-text'>Make Big Money</span> in Crypto!</p>
                        </Col>  
                    </Row>
                </Container>
            </div>

            <section className='business-2'>
                <Container className='relative z-index-3'>
                    <Row>
                        <Col>
                            <div className='v2-mt--2 v2-tp-mt-0 img-wrap'>
                                <a href='/'><img className='business-2-logo'  width={112} src={OvatoLogo} /></a>
                            </div>

                            <h1 className='bebas-neue v2-font-66 white-text lh-1 v2-mw-300 v2-tp-mw-50 v2-m-mw-100 v2-mt-0 relative z-index-4'>Easiest and  Duplicatable opportunity  in Crypto !</h1>

                            <ul className='v2-mt-1-5 business-ul'>
                                <li>Great Compensation Package</li>
                                <li>Royalties Forever </li>
                                <li>Assets that Appreciate</li>
                            </ul>

                            <div className='v2-mt-1-5 business-star'>
                                <img src={IconStar} width={90} /> 4.8 stars
                            </div>

                            <div className='v2-mt-2-5 btn-wrap relative z-index-2'>
                                <a target='_blank' href={withAffCodeURL(WALLET_URL)} className='business-btn'>Get Started</a>
                            </div>

                            <BusinessVideo videoURL={'https://www.youtube.com/watch?v=cekUUvu8JIM'} />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='business-3'>
                <Container>
                    <Row className='align-items-end relative z-index-2 v2-mt--16 v2-tp-mt-0'>
                        <Col className='v2-pr-2' sm={7}>
                            <div className='text-right v2-m-text-center img-wrap'>
                                <img width={338} src={OvatoWallet} className='v2-tl-mw-50 v2-tp-mw-80 v2-m-mw-70 v2-m-mt--6' />
                            </div>
                        </Col>

                        <Col sm={5}>
                            <h2 className='bebas-neue v2-font-100 v2-tl-font-80 v2-tp-font-70 white-text text-center lh-1 v2-mw-300 mx-auto business-3-h2-1'>Business
                            in-a-box</h2>

                            <p className='poppins v2-font-27 v2-tl-font-24 v2-tp-font-22 v2-m-font-20 lh-1-2 regular white-text text-center v2-mw-300 mx-auto'>Discover How To 
                            Make Explosive Income</p>

                            <div className='v2-mt-2 btn-wrap text-center'>
                                <a target='_blank' href={withAffCodeURL(WALLET_URL)} className='business-btn'>Get Started</a>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className='business-box'>

                                <h2 className='poppins v2-font-26 v2-tl-font-24 v2-tp-font-22 medium dark-text-12 text-center business-box-title'><span>EASIEST WAY TO EARN IN CRYPTO</span></h2>

                                <ul className='business-books'>
                                    <li>
                                        <a href='#'><img src={ImgIncome} /></a>
                                    </li>
                                    <li>
                                        <a href='#'><img src={ImgRoyalties} /></a>
                                    </li>
                                    <li>
                                        <a href='#'><img src={ImgExit} /></a>
                                    </li>
                                    <li>
                                        <a href='#'><img src={ImgTools} /></a>
                                    </li>
                                    <li>
                                        <a href='#'><img src={ImgTradable} /></a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
  }
}

export default BusinessInABox;