import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNav3';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import '../assets/css/Stacking3.css';

import imgGovatoSmall from '../assets/img/img-govato-small.png';
import imgBanner from '../assets/img/img-stacking-2-banner.png';
import imgBanner2 from '../assets/img/img-stacking-banner-2-new.png';
import imgStackForCoins from '../assets/img/img-stacking-3-for-coins.png'
import imgGrowCoins from '../assets/img/img-stacking-3-grow-your-coins.png'
import imgGrowCoinsMobile from '../assets/img/img-grow-coins-mobile.png'
import imgValue from '../assets/img/img-stacking-3-value.png'
import imgGrowValue from '../assets/img/img-stacking-3-grow-value.png'
import imgGrowValueMobile from '../assets/img/img-grow-value-mobile.png'
import imgVideo from '../assets/img/img-stacking-3-video.jpg'

import imgGovato from '../assets/img/logo-govato-stacking.png'
import imgNFT from '../assets/img/img-stacking-nft.png'
import imgVideoFinally from '../assets/img/img-video-finally.jpg'

import imgVideoLeft from '../assets/img/img-s4-video-left.png'
import imgVideoRight from '../assets/img/img-s4-video-right.png'

// Components
import TermsBox from './stacking-5/TermsBox';

// New CSS 
import '../assets/css/Stacking5.css';

// New
import ImgBanner from '../assets/img/stacking-5/img-banner.png'
import ImgGold from '../assets/img/stacking-5/img-gold.png'
import ImgGraph from '../assets/img/stacking-5/img-graph.png'
import ImgVideo from '../assets/img/stacking-5/img-video.jpg'


class Stacking5 extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='white-header support-main s5-main'>
        {/* Header */}
        <TopNav />
        
        {/* Banner */}
        <section className='s3-banner s5-banner'>
            <Container>
                <Row className='align-center'>
                    <Col sm={9}>
                        <h1 className='text-center poppins v2-font-45 v2-tl-font-36 v2-tp-font-32 v2-m-font-28 bold white-text'>Grow Your Holdings Exponentially By Baking Them In Your NFT</h1>
                    </Col>

                    <Col sm={3}>
                        <div className='img-wrap s5-banner-img '>
                            <img src={ImgBanner} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        {/* Section 2 */}
        <section className='s3-2 s4-2 s5-2'>
            <Container>
                <Row>
                    <Col>
                        <h3 className='poppins n-font-35 tt-n-font-28 m-n-font-22 bold dark-text-5 text-center'>Select from 5 great terms</h3>
                    </Col>
                </Row>

                <Row className='mt-80 s5-term-wrap'>
                    <Col className='s5-term-col' md={2}>
                        <TermsBox num={1} year={'3 Months'} percent={'7%'} example={'1 = 1.07'} />
                    </Col>

                    <Col className='s5-term-col' md={2}>
                        <TermsBox num={2} year={'6 Months'} percent={'15%'} example={'1 = 1.15'} />
                    </Col>

                    <Col className='s5-term-col' md={2}>
                        <TermsBox num={3} year={'12 Months'} percent={'32%'} example={'1 = 1.32'} />
                    </Col>

                    <Col className='s5-term-col' md={2}>
                        <TermsBox num={4} year={'18 Months'} percent={'48%'} example={'1 = 1.48'} />
                    </Col>

                    <Col className='s5-term-col' md={2}>
                        <TermsBox num={5} year={'24 Months'} percent={'70%'} example={'1 = 1.70'} />
                    </Col>
                </Row>

                <Row className='mt-60 tt-mt-80 m-mt-40'>
                    <Col>
                        <h2 className='poppins n-font-37 tt-n-font-32 m-n-font-28 medium text-center blue-text s3-2-heading'><span>Choose</span></h2>
                    </Col>
                </Row>

                <Row className='mt-40 align-center s3-2-choose-2 s5-2-choose-2'>
                    <Col md={5}>
                        <h3 className='poppins n-font-34 t-n-font-28 m-n-font-24 bold dark-text-5 text-center'>Stack For Coins</h3>
                        <div className='s3-2-box s3-2-box-2 mt-60 t-mt-60'>
                            <p className='s3-2-box-num'>1</p>

                            <div className='img-wrap text-center mt-20 s5-2-choose-2-img-1'>
                                <img src={ImgGold} width={299} />
                            </div>

                            <div className='img-wrap s3-2-box-img-float-left'>
                                <img src={imgGrowCoins} width={131} className='desktop' />
                                <img src={imgGrowCoinsMobile} width={131} className='mobile' />
                            </div>
                        </div>
                    </Col>

                    <Col md={1}>
                        <p className='poppins n-font-37 m-n-font-32 medium blue-text text-center mt-100 tt-mt-20'>or</p>
                    </Col>

                    <Col md={5}>
                        <h3 className='poppins n-font-34 t-n-font-28 m-n-font-24 bold dark-tex-5 text-center v2-m-mt-1'>Stack For Value</h3>

                        <div className='s3-2-box s3-2-box s3-2-box-2 mt-60 t-mt-60'>
                            <p className='s3-2-box-num'>2</p>

                            <div className='img-wrap text-center mt-20 s5-2-choose-2-img-2'>
                                <img src={ImgGraph} width={249} />
                            </div>

                            <div className='img-wrap s3-2-box-img-float-right'>
                                <img src={imgGrowValue} width={145} className='desktop' />
                                <img src={imgGrowValueMobile} width={145} className='mobile' />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='v2-pb-6 s3-works'>
                    <Col>
                        <div className='img-wrap text-center v2-mt-10 s3-video-wrap s4-video-wrap s5-video-wrap'>
                            <a href='https://youtu.be/f_w6CzDuadE' data-fancybox>
                                <img width={725} src={ImgVideo} />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* Footer */}
        <BottomNav />
      </div>
    );
  }
}
export default withTranslation()(Stacking5);