import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import ImgSample from '../../assets/img/leaderboard-top-5/img-sample-img.png'

import Flag from 'react-world-flags';
import { getCode } from 'country-list';

const renderCountryFlag = (countryName) => {
    // Convert country name to ISO 3166-1 alpha-2 country code
    const countryCode = getCode(countryName);
  
    return (
        <>
        {countryCode ? <Flag code={countryCode} alt={`${countryName} flag`} /> : null}
        </>
    );
  };

const formatEarnings = (number) => {
    const formattedEarnings = new Intl.NumberFormat('en-US').format(number);
    return formattedEarnings;
}

class LeaderboardTopBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }
  render() {
    const { t, data, index, image } = this.props;
    return (
        <div className='leaderboard-top-box'>
            <div className='leaderboard-top-box-img'><img src={image} /></div>
            
            <div className='leaderboard-top-box-footer'>
                <div className='leaderboard-top-box-country' title={data.country}>{renderCountryFlag(data.country)}</div>
                <div className='leaderboard-top-box-name' title={data.name}>{data.name}</div>
                {/* <div className='leaderboard-top-box-earnings'>Total Earnings: 25,691 USD</div> */}
                <div className='leaderboard-top-box-earnings' title={formatEarnings(data.total_earnings) + ' USD'}>Total Earnings: {formatEarnings(data.total_earnings)} USD</div>
            </div>
        </div>
    );
  }
}
export default withTranslation()(LeaderboardTopBox);