import React, { useEffect } from "react";

import { Fancybox } from "@fancyapps/ui";

import "@fancyapps/ui/dist/fancybox.css";

import ImgPlay from '../../assets/img/discover2/img-right-arrow.png'
import ImgWatch from '../../assets/img/discover2/img-watch-video-text.png'
import ImgWatchWhite from '../../assets/img/business-in-a-box-merchant/img-watch-video.png'

const BusinessVideo = ({ videoURL, videoThumb = '', options = {}, watchVideoTextMobile = 'default' }) => {
  // Initialize Fancybox for videos
  useEffect(() => {
    Fancybox.bind("[data-fancybox='video']", options);
    return () => {
      Fancybox.destroy(); // Clean up when the component unmounts
    };
  }, [options]);

  return (
    <div className='v2-discover-play-btn-wrap'>
        <a href={videoURL} className='v2-discover-play-btn' data-fancybox="video">
            <div className='v2-discover-glow'></div>
            <div className='v2-discover-play-img'>
                <img src={ImgPlay} />
            </div>
            <div className='v2-discover-watch-video-text desktop'>
                <img src={ImgWatch} />
            </div>
            <div className='v2-discover-watch-video-text mobile'>
                { watchVideoTextMobile == 'white' ? <img src={ImgWatchWhite} /> :  <img src={ImgWatch} /> }
            </div>
        </a>
    </div>
  );
};

export default BusinessVideo;